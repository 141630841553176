// src/components/LocationSection.tsx
import React from 'react';
import { ReactComponent as LocationBubbleSvg } from '../../assets/images/location-bubble.svg';
import AnimatedLink from '../common/AnimatedLink';

const LocationSection: React.FC = () => (
  <section className="py-12 sm:py-24 text-white">
    <div className="container mx-auto px-4">
      <div className="flex flex-col gap-16 sm:gap-32">
        {/* Santa Barbara Section */}
        <div className="flex flex-col items-center sm:items-start w-full">
          <h3 className="font-paperboard text-3xl sm:text-4xl">SANTA BARBARA</h3>
          <p className="font-satoshi font-medium flex items-center justify-center mb-0 text-center sm:text-left mt-2">
            <AnimatedLink 
              href="https://maps.app.goo.gl/nfSBP4NDmfrbTAwE6" // Updated Embed Link
              targetBlank
              ariaLabel="Get Directions to Santa Barbara Location"
              className="flex items-center hover:text-holdrens-red transition-colors duration-300"
            >
              512 State Street, Santa Barbara, CA
              <LocationBubbleSvg className="w-4 h-4 ml-2" />
            </AnimatedLink>
          </p>
          <AnimatedLink 
            href="tel:+18059653363" 
            ariaLabel="Call Santa Barbara Location"
            className="font-paperboard font-bold text-lg block mt-2 hover:text-holdrens-red transition-colors duration-300"
          >
            805-965-3363
          </AnimatedLink>

          {/* Hours - Santa Barbara */}
          <div className="mt-2 text-center sm:text-left font-satoshi text-sm sm:text-base">
            <p className="font-medium mb-1">Hours:</p>
            <ul className="list-none p-0 m-0">
              <li>Mon - Fri: 4-8:30 PM</li>
              <li>Sat & Sun 3-9 PM</li>
            </ul>
          </div>

          <div className="relative w-full h-auto mt-8 sm:mt-12">
            {/* Embedded Google Map for Santa Barbara */}
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13165.503226875393!2d-119.71331326044923!3d34.417208099999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80e91387fbebd4a1%3A0x8c9fa56066c29efd!2sHoldren&#39;s%20Steaks%20%26%20Seafood!5e0!3m2!1sen!2sus!4v1728938649703!5m2!1sen!2sus"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Santa Barbara Location Map"
              className="w-full h-64 sm:h-80 md:h-96 object-cover rounded-md"
            ></iframe>
          </div>
        </div>

        {/* Newbury Park Section */}
        <div className="flex flex-col items-center sm:items-end w-full">
          <h3 className="font-paperboard text-3xl sm:text-4xl">NEWBURY PARK</h3>
          <p className="font-satoshi font-medium flex items-center justify-center mb-0 text-center sm:text-right mt-2">
            <LocationBubbleSvg className="w-4 h-4 mr-2" />
            <AnimatedLink 
              href="https://maps.app.goo.gl/mJZMH1acuLoa4Y2A7" // Updated Embed Link
              targetBlank
              ariaLabel="Get Directions to Newbury Park Location"
              className="flex items-center hover:text-holdrens-red transition-colors duration-300"
            >
              1714-A Newbury Road, Newbury Park, CA
            </AnimatedLink>
          </p>
          <AnimatedLink 
            href="tel:+18054981314" 
            ariaLabel="Call Newbury Park Location"
            className="font-paperboard font-bold text-lg block mt-2 hover:text-holdrens-red transition-colors duration-300"
          >
            805-498-1314
          </AnimatedLink>

          {/* Hours - Newbury Park */}
          <div className="mt-2 text-center sm:text-right font-satoshi text-sm sm:text-base">
            <p className="font-medium mb-1">Hours:</p>
            <ul className="list-none p-0 m-0">
              <li>Sun – Thu: 4–8:30 PM</li>
              <li>Fri &amp; Sat: 4–9:30 PM</li>
            </ul>
          </div>

          <div className="relative w-full h-auto mt-8 sm:mt-12">
            {/* Embedded Google Map for Newbury Park */}
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13202.277022772969!2d-118.93189675924329!3d34.18292901081744!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80e8309d5251834b%3A0x477b6c7e552934d6!2sHoldren&#39;s%20Steaks%20%26%20Seafood!5e0!3m2!1sen!2sus!4v1728938623761!5m2!1sen!2sus"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Newbury Park Location Map"
              className="w-full h-64 sm:h-80 md:h-96 object-cover rounded-md"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default LocationSection;